import {useJsApiLoader, GoogleMap, Marker} from '@react-google-maps/api';
import i18n from 'i18next';
import {VStack} from 'platform/foundation';
import styled from 'styled-components';

import {useEffect, useState} from 'react';

import {isNil, isNotNil} from 'ramda';

import {environment} from '@dms/environment';

import {ButtonGroup} from '../ButtonGroup/ButtonGroup';
import {DataStatus} from '../DataStatus/DataStatus';
import {closeCurrentDialog} from '../Dialog/utils/closeCurrentDialog';

// prague coords
const DEFAULT_COORDS = {lat: 50.0755381, lng: 14.4378005};

interface MapViewProps {
  handleSubmitAddress: (placeId: string) => void;
  defaultPlaceId?: string;
}

export function MapView(props: MapViewProps) {
  const [startingCoords, setStartingCoords] = useState<google.maps.LatLngLiteral | null>(null);

  const {isLoaded, loadError} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: environment.GOOGLE_API_KEY,
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const geocoder = isLoaded ? new window.google.maps.Geocoder() : undefined;

  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral | null>(null);
  const [placeId, setPlaceId] = useState<string | undefined>(props.defaultPlaceId);

  const handleOnClick = (event: google.maps.MapMouseEvent) => {
    if (isNil(event.latLng)) {
      return;
    }
    const newCoords = {lat: event.latLng.lat(), lng: event.latLng.lng()};

    setMarkerPosition(newCoords);
    map?.panTo(newCoords);
    if (geocoder) {
      geocoder.geocode({location: event.latLng}, (results, status) => {
        const firstResult = results?.[0];

        if (status === 'OK' && isNotNil(firstResult)) {
          setPlaceId(firstResult.place_id);
        }
      });
    }
  };

  useEffect(() => {
    if (isNil(map)) {
      return;
    }

    setMarkerPosition(startingCoords);
  }, [map, startingCoords]);

  useEffect(() => {
    if (isNotNil(props.defaultPlaceId)) {
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newCoords = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          setStartingCoords(newCoords);
        },
        () => setStartingCoords(DEFAULT_COORDS)
      );
    } else {
      setStartingCoords(DEFAULT_COORDS);
    }
  }, [props.defaultPlaceId, startingCoords]);

  useEffect(() => {
    if (props.defaultPlaceId && geocoder) {
      geocoder.geocode({placeId: props.defaultPlaceId}, (results, status) => {
        const firstResult = results?.[0];

        if (status === 'OK' && isNotNil(firstResult)) {
          setStartingCoords({
            lat: firstResult.geometry.location.lat(),
            lng: firstResult.geometry.location.lng(),
          });
        }
      });
    }
  }, [props.defaultPlaceId, geocoder]);

  return (
    <DataStatus
      isLoading={!isLoaded || isNil(startingCoords)}
      isError={!!loadError}
      minHeight="80%"
    >
      <VStack spacing={4} height={160}>
        <MapWrapper>
          <GoogleMap
            mapContainerStyle={{position: 'absolute', height: '100%', width: '100%'}}
            zoom={12}
            center={startingCoords ?? DEFAULT_COORDS}
            onClick={handleOnClick}
            onLoad={setMap}
          >
            {markerPosition && <Marker position={markerPosition} />}
          </GoogleMap>
        </MapWrapper>
        <ButtonGroup
          align="right"
          buttons={[
            {
              variant: 'secondary',
              onClick: closeCurrentDialog,
              title: i18n.t('general.actions.discard'),
            },
            {
              onClick: () => {
                if (isNotNil(placeId)) {
                  props.handleSubmitAddress(placeId);
                }

                closeCurrentDialog();
              },
              type: 'submit',
              title: i18n.t('general.actions.confirm'),
            },
          ]}
        />
      </VStack>
    </DataStatus>
  );
}

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
