import {VStack} from 'platform/foundation';

import {isNotNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {useCatalogue} from '../../../hooks/useCatalogue';
import {usePriceReport} from '../../../hooks/usePriceReport';
import {AvailableCarWithLogo} from '../types/available-car-with-logo';
import {createVehicleKey} from '../utils/createVehicleKey';
import {VehicleCard} from './VehicleCard';

interface AdvertisementRowLayoutProps extends TestIdProps {
  vehicles: AvailableCarWithLogo[];
}

export function AdvertisementRowLayout(props: AdvertisementRowLayoutProps) {
  const {priceReport} = usePriceReport();
  const catalogue = useCatalogue();

  return isNotNil(priceReport) ? (
    <VStack spacing={2}>
      {props.vehicles.map((vehicle, index) => (
        <VehicleCard
          priceReport={priceReport}
          catalogue={catalogue}
          key={createVehicleKey(vehicle)}
          vehicle={vehicle}
          isMyVehicle={vehicle.isMyVehicle}
          data-testid={suffixTestId(vehicle.isMyVehicle ? 'myVehicle' : `vehicle[${index}]`, props)}
        />
      ))}
    </VStack>
  ) : null;
}
