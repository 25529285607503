import {DataStatus} from 'platform/components';
import {Box, Hide, Show, ThemeColorPath} from 'platform/foundation';

import {useEffect} from 'react';
import {Navigate, Outlet, useLocation, useMatch} from 'react-router-dom';

import {SelectedFiltersProvider} from '../../hooks/SelectedFiltersContext';
import {useCatalogue} from '../../hooks/useCatalogue';
import {usePriceReport} from '../../hooks/usePriceReport';
import {useVehicleTranslatedInfo} from '../../hooks/useVehicleTranslatedInfo';
import {VEHICLETYPE_PASSENGER_CAR} from '../../pages/Create/constants/vehiclePassengerCar';
import {VehicleFormType} from '../../pages/Create/types/VehicleFormType';
import {getDefaultValues} from '../../pages/Create/utils/getDefaultValues';
import {ErrorPage} from '../../pages/ErrorPage/ErrorPage';
import {ExpiredPage} from '../../pages/ExpiredPage/ExpiredPage';
import {useIsPrintForced} from '../../pages/Overview/hooks/useIsPrintForced';
import {getTestId} from '../../utils/getTestId';
import {createPath, createRoute} from '../../utils/routing';
import {Footer} from './components/Footer/Footer';
import {Header} from './components/Header/Header';

const PRINT_WIDTH = 297;

export function Layout() {
  const {
    priceReport,
    requestData: request,
    isLoading: isPriceReportLoading,
    isError,
    isNotCreated,
    isNotFound,
    isExpired,
  } = usePriceReport();
  const {isLoading: isCatalogueLoading} = useCatalogue();
  const dataForTranslationWhenReportIsCreated = priceReport?.vehicle;
  const dataForTranslationWhenReportIsNotCreated = {
    ...(getDefaultValues(request?.vehicle) as VehicleFormType),
    vehicleType: VEHICLETYPE_PASSENGER_CAR,
  };
  const {isLoading: isLoadingVehicleTranslatedInfo} = useVehicleTranslatedInfo(
    dataForTranslationWhenReportIsCreated ?? dataForTranslationWhenReportIsNotCreated
  );

  const isCreatePage = !!useMatch(createPath);
  const shouldRedirect = !isCreatePage && isNotCreated;
  const isLoading =
    isPriceReportLoading || isCatalogueLoading || isLoadingVehicleTranslatedInfo || shouldRedirect;
  const {isPrintForced, getResponsiveValue} = useIsPrintForced();

  const pageBackgroundColor: ThemeColorPath = getResponsiveValue(
    'palettes.neutral.10.100',
    'palettes.neutral.10.100',
    'palettes.neutral.10.100',
    'palettes.neutral.10.100',
    'general.white'
  );

  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!isLoading) {
      document.getElementById('loading-screen')?.remove();
    }
  }, [isLoading]);

  if (shouldRedirect) {
    return <Navigate to={createRoute} />;
  }

  return (
    <Box width={isPrintForced ? PRINT_WIDTH : 'auto'}>
      <Hide when={isNotFound || isExpired}>
        <SelectedFiltersProvider>
          <DataStatus
            minHeight="100vh"
            isError={isError}
            data-testid={getTestId('layout', 'dataStatus')}
          >
            <Box
              backgroundColor={pageBackgroundColor}
              // Page is being rendered under the loading screen so browser can preload images and fonts
              // Disable scrolling the page when loading screen is shown
              height={isLoading ? '100dvh' : undefined}
              overflow={isLoading ? 'hidden' : undefined}
            >
              <Header data-testid={getTestId('layout', 'header')} />
              <Outlet />
              <Footer data-testid={getTestId('layout', 'footer')} />
            </Box>
          </DataStatus>
        </SelectedFiltersProvider>
      </Hide>
      <Show when={isExpired}>
        <ExpiredPage data-testid="expiredPage" />
      </Show>
      <Show when={isNotFound}>
        <ErrorPage data-testid="errorPage" />
      </Show>
    </Box>
  );
}
