import {format} from 'date-fns';
import {Align, Box, Heading, Hide, Space, useDevice} from 'platform/foundation';
import {convertStringLocaleToDateFns, useFormatNumber, useLocale} from 'platform/locale';
import {
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  YAxisProps,
} from 'recharts';
import {useTheme} from 'styled-components';

import {isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {i18n} from '@price-report/shared';

import {capitalize} from 'shared';

import {PRINT_CHART_WIDTH} from '../../../../../constants/printChartWidth';
import {isInCypress} from '../../../../../utils/isInCypress';
import {ChartLegend} from '../../../components/ChartLegend';
import {useIsPrintForced} from '../../../hooks/useIsPrintForced';
import {getLineChartLabelDy} from '../../../utils/getLineChartLabelDy';

interface AverageStockChartProps {
  averageSold?: number[];
  averageStock?: number[];
  period?: string[];
}

const CHART_HEIGHT = 250;
const AXIS_OFFSET = 100;
const ROUND_TO_VALUE = 100;
const axisDomain: YAxisProps['domain'] = [
  (minValue: number) =>
    Math.max(0, Math.floor((minValue - AXIS_OFFSET) / ROUND_TO_VALUE) * ROUND_TO_VALUE),
  'auto',
];

export function AverageStockChart(props: AverageStockChartProps) {
  const theme = useTheme();
  const device = useDevice();
  const {getResponsiveValue} = useIsPrintForced();
  const formatNumber = useFormatNumber();
  const {language} = useLocale();

  const chartData = props.period?.map((period, index) => {
    const [yearString, monthString] = period.split('-');
    const year = parseInt(yearString);
    const month = parseInt(monthString) - 1;
    return {
      sold: props.averageSold?.[index] ?? 0,
      stock: props.averageStock?.[index] ?? 0,
      month: capitalize(
        format(new Date(year, month), 'LLLL', {
          locale: convertStringLocaleToDateFns(language),
        })
      ),
    };
  });

  const isSmallDevice = device === 'mobile' || device === 'tablet';
  const chartWidth = getResponsiveValue('100%', '100%', '100%', '100%', PRINT_CHART_WIDTH);
  const isAnimationActive = getResponsiveValue(true, true, true, true, false) && !isInCypress;

  return (
    <Hide when={isNilOrEmpty(chartData)}>
      <Box>
        <Align center>
          <Heading size={4}>{i18n.t('entity.overviewChart.labels.averageStockChart')}</Heading>
        </Align>
        <Space vertical={6} />
        <ChartLegend />
        <Space vertical={2} />
        <ResponsiveContainer width={chartWidth} height={CHART_HEIGHT}>
          <ComposedChart
            data={chartData}
            margin={isSmallDevice ? {bottom: 40, right: 20, top: 10} : {top: 10}}
            /* CSS is needed to allow line label overflow from the chart, because it is moved up (`dy={-6}`) */
            /* eslint-disable-next-line react/forbid-component-props */
            css={`
              .recharts-surface {
                overflow: visible;
              }
            `}
          >
            <CartesianGrid stroke={theme.colors.palettes.neutral[30][100]} vertical={false} />
            {isNotNil(props.averageSold) && (
              <Line
                dataKey="sold"
                stroke={theme.colors.palettes.neutral[60][100]}
                strokeWidth={3}
                dot={false}
                label={({viewBox, value, index}) => (
                  <text
                    x={viewBox.x}
                    y={viewBox.y}
                    textAnchor="middle"
                    dy={getLineChartLabelDy(index, 'sold', CHART_HEIGHT, chartData)}
                    fontSize={10}
                    fill={theme.colors.palettes.neutral[200][100]}
                  >
                    {formatNumber(value)}
                  </text>
                )}
                isAnimationActive={isAnimationActive}
              />
            )}
            {isNotNil(props.averageStock) && (
              <Line
                dataKey="stock"
                stroke={theme.colors.palettes.orange[60][100]}
                strokeWidth={3}
                dot={false}
                label={({viewBox, value, index}) => (
                  <text
                    x={viewBox.x}
                    y={viewBox.y}
                    textAnchor="middle"
                    dy={getLineChartLabelDy(index, 'stock', CHART_HEIGHT, chartData)}
                    fontSize={10}
                    fill={theme.colors.palettes.orange[80][100]}
                  >
                    {formatNumber(value)}
                  </text>
                )}
                isAnimationActive={isAnimationActive}
              />
            )}
            <YAxis
              type="number"
              tickFormatter={(value) => `${formatNumber(value)}`}
              tickLine={false}
              fontSize={12}
              axisLine={false}
              tick={{fill: theme.colors.text.primary}}
              domain={axisDomain}
            >
              <Label
                value={i18n.t('entity.overviewChart.labels.vehicleAmount')}
                angle={-90}
                position="insideLeft"
                fontSize={12}
                fill={theme.colors.text.primary}
              />
            </YAxis>
            <XAxis
              dataKey="month"
              fontSize={12}
              tick={{fill: theme.colors.text.primary}}
              axisLine={false}
              tickLine={false}
              padding={{left: 20, right: 20}}
              angle={isSmallDevice ? 60 : 0}
              textAnchor={isSmallDevice ? 'start' : 'middle'}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Hide>
  );
}
