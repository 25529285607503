import {OptionType} from 'platform/components';

import {isNil} from 'ramda';
import {isValidNumber} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {FilterFormData} from '../types/FilterFormData';
import {VehicleType} from '../types/Vehicle';
import {getYearOptions} from './getYearOptions';

const DEFAULT_BODY_STYLE = [
  'CARSTYLE_HATCHBACK',
  'CARSTYLE_LIFTBACK',
  'CARSTYLE_SEDANS_SALOONS',
  'CARSTYLE_COMPACT',
];

const getDefaultValue = (
  value: number,
  options: OptionType<number, string>[],
  couldBeSame = false
) => {
  if (couldBeSame) {
    const foundValue = options.find((option) => parseInt(String(option.value)) === value)?.value;
    if (isValidNumber(foundValue)) {
      return {from: foundValue, to: foundValue};
    }
  }

  const toIndex = options.findIndex((option) => parseInt(String(option.value)) > value);

  const fromValue = options[toIndex - 1]?.value;
  const toValue = options[toIndex]?.value;

  return {from: fromValue, to: toValue};
};

const getDefaultBodyStyle = (value: string | undefined) => {
  if (isNil(value)) {
    return [];
  }

  return DEFAULT_BODY_STYLE.includes(value) ? DEFAULT_BODY_STYLE : [value];
};

export const getDefaultValues = (vehicle: VehicleType | Nullish): FilterFormData => ({
  address: null,
  radius: null,
  showSoldCars: true,
  showStockCars: true,
  priceGte: null,
  priceLte: null,
  isVatDeductible: [],
  firstRegistrationOnYearGte: vehicle
    ? getDefaultValue(vehicle.firstRegistrationOnYear, getYearOptions(), true)?.from
    : null,
  firstRegistrationOnYearLte: vehicle
    ? getDefaultValue(vehicle.firstRegistrationOnYear, getYearOptions(), true)?.to
    : null,
  mileageGte: null,
  mileageLte: null,
  transmission: vehicle ? [vehicle.transmission] : [],
  fuelType: vehicle ? [vehicle.fuelType] : [],
  powerGte: vehicle?.power,
  powerLte: vehicle?.power,
  bodyStyle: getDefaultBodyStyle(vehicle?.bodyStyle),
  drive:
    vehicle && vehicle.drive !== 'DRIVE_REAR' && vehicle.drive !== 'DRIVE_FRONT'
      ? [vehicle.drive]
      : ['DRIVE_4X2'],
});
