import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {fetchBaseQuery} from '@reduxjs/toolkit/query';

import {environment} from '@price-report/environment';
import {
  AddrDetailApiArg,
  AddrDetailApiResponse,
  AddrSuggestionsApiArg,
  AddrSuggestionsApiResponse,
} from '@price-report/shared';

export const addressApi = createApi({
  baseQuery: fetchBaseQuery({baseUrl: environment.METADA_URL}),
  reducerPath: 'address',
  endpoints: (build) => ({
    addrDetail: build.query<AddrDetailApiResponse, AddrDetailApiArg>({
      query: (queryArg) => ({
        url: `address/v1/detail/${queryArg.placeId}`,
        params: {language: queryArg.language},
      }),
    }),
    addrSuggestions: build.query<AddrSuggestionsApiResponse, AddrSuggestionsApiArg>({
      query: (queryArg) => ({
        url: `address/v1/suggestions`,
        params: {term: queryArg.term, country: queryArg.country, language: queryArg.language},
      }),
    }),
  }),
});

export const {
  useAddrDetailQuery,
  useAddrSuggestionsQuery,
  useLazyAddrSuggestionsQuery,
  useLazyAddrDetailQuery,
} = addressApi;
