import {HStack, Show} from 'platform/foundation';

import {useMatch, useParams} from 'react-router-dom';

import {featureFlags, i18n} from '@price-report/shared';

import {getTestId} from '../../../../../utils/getTestId';
import {
  advertisementPath,
  drillDownPath,
  getAdvertisementPath,
  getDrillDownPath,
  getOverviewPath,
  getStatisticsPath,
  overviewPath,
  statisticsPath,
} from '../../../../../utils/routing';
import {NavigationLink} from './NavigationLink';

interface NavigationProps {
  isLinksDisabled?: boolean;
  shouldScrollActiveLinkIntoView?: boolean;
}

export function Navigation(props: NavigationProps) {
  const params = useParams();
  const isOverviewActive = !!useMatch(overviewPath);
  const isStatisticsActive = !!useMatch(statisticsPath);
  const isAdvertisementActive = !!useMatch(advertisementPath);
  const isDrillDownActive = !!useMatch(drillDownPath);

  return (
    <HStack spacing={6} height={12}>
      <NavigationLink
        isActive={isOverviewActive}
        isDisabled={props.isLinksDisabled}
        to={getOverviewPath(params)}
        shouldScrollIntoView={props.shouldScrollActiveLinkIntoView}
        data-testid={getTestId('header', 'linkToOverview')}
      >
        {i18n.t('page.overview.title')}
      </NavigationLink>
      <NavigationLink
        isActive={isStatisticsActive}
        isDisabled={props.isLinksDisabled}
        to={getStatisticsPath(params)}
        shouldScrollIntoView={props.shouldScrollActiveLinkIntoView}
        data-testid={getTestId('header', 'linkToStatistics')}
      >
        {i18n.t('page.statistics.title')}
      </NavigationLink>
      <NavigationLink
        isActive={isAdvertisementActive}
        isDisabled={props.isLinksDisabled}
        to={getAdvertisementPath(params)}
        shouldScrollIntoView={props.shouldScrollActiveLinkIntoView}
        data-testid={getTestId('header', 'linkToAdvertisement')}
      >
        {i18n.t('page.advertisiments.title')}
      </NavigationLink>
      <Show whenFeatureEnabled={featureFlags.PRICE_REPORT_DRILL_DOWN}>
        <NavigationLink
          isActive={isDrillDownActive}
          isDisabled={props.isLinksDisabled}
          to={getDrillDownPath(params)}
          shouldScrollIntoView={props.shouldScrollActiveLinkIntoView}
          data-testid={getTestId('header', 'linkToDrilldown')}
        >
          {i18n.t('page.drillDown.title')}
        </NavigationLink>
      </Show>
    </HStack>
  );
}
