import {Box, CSSDimension, HStack, Integer, ValueByDevice} from 'platform/foundation';

import {useId} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {FormControlProps} from '../../types/FormControlProps';
import {SegmentItem, SegmentItemBaseProps} from './SegmentItem';

export interface SegmentProps<T extends string = string> extends FormControlProps<T>, TestIdProps {
  options?: SegmentItemBaseProps<T>[];
  minWidth?: CSSDimension | Integer | ValueByDevice<CSSDimension | Integer>;
}

export function Segment<T extends string = string>(props: SegmentProps<T>) {
  const id = useId();
  const count = props.options?.length ?? 0;

  return (
    <Box
      padding={1}
      backgroundColor="palettes.neutral.20.100"
      borderRadius="small"
      data-itemcount={count}
      data-testid={suffixTestId('segmentWrapper', props)}
    >
      <HStack spacing={1} data-testid={suffixTestId('segmentWrapper', props)}>
        {props.options?.map((item) => (
          <SegmentItem<T>
            {...item}
            key={item.value}
            id={id}
            onClick={props.onChange}
            isSelected={item.value === props.value}
            minWidth={props.minWidth}
            data-testid={suffixTestId(`segmentItem-${item.value}`, props)}
          />
        ))}
      </HStack>
    </Box>
  );
}
