import {Hide, HStack} from 'platform/foundation';

import {isNotNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {ScrollWithHiddenScrollbarInCypress} from '../../../components/ScrollWithHiddenScrollbarInCypress/ScrollWithHiddenScrollbarInCypress';
import {useCatalogue} from '../../../hooks/useCatalogue';
import {usePriceReport} from '../../../hooks/usePriceReport';
import {AvailableCarWithLogo} from '../types/available-car-with-logo';
import {createVehicleKey} from '../utils/createVehicleKey';
import {ColumnLayoutDescription} from './ColumnLayoutDescription';
import {VehicleColumn} from './VehicleColumn';

interface AdvertisementColumnLayoutProps extends TestIdProps {
  vehicles: AvailableCarWithLogo[];
  getVehiclePosition: (vehicle: AvailableCarWithLogo, index: number) => number;
}

export function AdvertisementColumnLayout(props: AdvertisementColumnLayoutProps) {
  const {priceReport} = usePriceReport();
  const catalogue = useCatalogue();

  const vehicleFeatures = (
    props.vehicles.reduce((features: string[], result) => {
      const featuresToAdd = result.features?.filter((feat) => !features.includes(feat));
      if (!featuresToAdd) {
        return features;
      }
      return [...features, ...featuresToAdd];
    }, priceReport?.vehicle.features ?? []) ?? priceReport?.vehicle.features
  ).filter(catalogue.hasFeatureTranslation);

  return isNotNil(priceReport) ? (
    <ScrollWithHiddenScrollbarInCypress>
      <HStack minWidth="min-content">
        <Hide onMobile>
          <ColumnLayoutDescription />
        </Hide>
        {props.vehicles.map((vehicle, index) => (
          <VehicleColumn
            key={createVehicleKey(vehicle)}
            priceReport={priceReport}
            catalogue={catalogue}
            vehicle={vehicle}
            isMyVehicle={vehicle.isMyVehicle}
            vehiclePosition={props.getVehiclePosition(vehicle, index)}
            vehicleFeatures={vehicleFeatures}
            data-testid={suffixTestId(
              vehicle.isMyVehicle ? 'myVehicle' : `vehicle[${index}]`,
              props
            )}
          />
        ))}
      </HStack>
    </ScrollWithHiddenScrollbarInCypress>
  ) : null;
}
