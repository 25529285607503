import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {isNil} from 'ramda';

import {environment} from '@price-report/environment';
import {
  AvailableCar,
  CreatePriceReportFromRequestApiArg,
  CreatePriceReportFromRequestApiResponse,
  GetAvailableCarsApiArg,
  GetAvailableCarsApiResponse,
  GetCarFeaturesListApiArg,
  GetCarFeaturesListApiResponse,
  GetPriceReportApiArg,
  GetPriceReportApiResponse,
  GetPriceReportCarDetailApiArg,
  GetPriceReportCarsDetailApiArg,
  GetRequestApiArg,
  GetRequestApiResponse,
  GetRequestCountsApiArg,
  GetRequestCountsApiResponse,
  GetRequestVinDetailsApiArg,
  GetStatisticsDaysOnStockChartApiArg,
  GetStatisticsDaysOnStockChartApiResponse,
  GetStatisticsFeaturesLevelChartApiArg,
  GetStatisticsFeaturesLevelChartApiResponse,
  GetStatisticsMileageChartApiArg,
  GetStatisticsMileageChartApiResponse,
  GetStatisticsPriceChartApiArg,
  GetStatisticsPriceChartApiResponse,
  GetStatisticsPriceMapChartApiArg,
  GetStatisticsPriceMapChartApiResponse,
  GetStatisticsV2ApiArg,
  GetStatisticsV2ApiResponse,
  UpdateRequestApiArg,
  UpdateRequestApiResponse,
  VinDecoderData,
} from '@price-report/shared';

import {fixAlfaBugsInDrillDownResponse} from '../utils/fixAlfaBugsInDrillDownResponse';

export const priceReportApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({baseUrl: environment.METADA_URL + 'pricereport/v1/'}),
  tagTypes: [
    'priceReport',
    'daysOnStock',
    'featuresLevel',
    'mileage',
    'price',
    'priceMap',
    'request',
    'requestCounts',
    'requestFeatures',
  ],
  endpoints: (builder) => ({
    getPriceReport: builder.query<GetPriceReportApiResponse, GetPriceReportApiArg>({
      query: ({reportId}) => ({url: `/pricereport/${reportId}`}),
      providesTags: (result, error, queryArg) => [{type: 'priceReport', id: queryArg.reportId}],
      /*
       *      // Pick out data and prevent nested properties in a hook or selector
       *      transformResponse: (response) => response.data,
       *      // `result` is the server response
       *      providesTags: (result, error, id) => [{ type: 'Post', id }],
       *      // trigger side effects or optimistic updates
       *      onQueryStarted(id, { dispatch, getState, extra, requestId, queryFulfilled, getCacheEntry, updateCachedData }) {},
       *      // handle subscriptions etc
       *      onCacheEntryAdded(id, { dispatch, getState, extra, requestId, cacheEntryRemoved, cacheDataLoaded, getCacheEntry, updateCachedData }) {},
       */
      // TODO T20-30972 - fix response on BE
      transformResponse: (response: GetPriceReportApiResponse): GetPriceReportApiResponse => ({
        ...response,
        markets: response.markets?.map((market) =>
          isNil(market.mileageChart)
            ? market
            : {
                ...market,
                mileageChart: fixAlfaBugsInDrillDownResponse(
                  [
                    'make',
                    'model',
                    'year',
                    'power',
                    'fuel_type',
                    'transmission',
                    'drive',
                    'car_style',
                    'mileage_range',
                  ],
                  market.mileageChart
                ),
              }
        ),
      }),
    }),
    getPriceReportCarDetail: builder.query<AvailableCar, GetPriceReportCarDetailApiArg>({
      query: ({reportId, vehicleId}) => ({
        url: `/pricereport/${reportId}/car_detail/${vehicleId}`,
        method: 'GET',
      }),
      transformResponse: (response: {result: AvailableCar}) => response.result,
    }),
    getPriceReportCarsDetail: builder.query<AvailableCar[], GetPriceReportCarsDetailApiArg>({
      query: ({reportId, vehicleIds}) => {
        const params = new URLSearchParams();
        vehicleIds.forEach((id) => params.append('adId', id));
        return {
          url: `/pricereport/${reportId}/car_details?${params.toString()}`,
          method: 'GET',
        };
      },
      transformResponse: (response: {result: AvailableCar[]}) => response.result,
    }),
    getAvailableCars: builder.query<GetAvailableCarsApiResponse, GetAvailableCarsApiArg>({
      query: (queryArg) => ({
        url: `/pricereport/${queryArg.id}/available_cars`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getStatisticsDaysOnStock: builder.query<
      GetStatisticsDaysOnStockChartApiResponse,
      GetStatisticsDaysOnStockChartApiArg
    >({
      query: (queryArg) => ({
        url: `/pricereport/${queryArg.reportId}/statistics_days_on_stock`,
        method: 'POST',
        body: queryArg.body,
      }),
      providesTags: (result, error, queryArg) => [{type: 'daysOnStock', id: queryArg.reportId}],
    }),
    getStatisticsFeaturesLevel: builder.query<
      GetStatisticsFeaturesLevelChartApiResponse,
      GetStatisticsFeaturesLevelChartApiArg
    >({
      query: (queryArg) => ({
        url: `/pricereport/${queryArg.reportId}/statistics_feature_score`,
        method: 'POST',
        body: queryArg.body,
      }),
      providesTags: (result, error, queryArg) => [{type: 'featuresLevel', id: queryArg.reportId}],
    }),
    getStatisticsMileage: builder.query<
      GetStatisticsMileageChartApiResponse,
      GetStatisticsMileageChartApiArg
    >({
      query: (queryArg) => ({
        url: `/pricereport/${queryArg.reportId}/statistics_mileage`,
        method: 'POST',
        body: queryArg.body,
      }),
      providesTags: (result, error, queryArg) => [{type: 'mileage', id: queryArg.reportId}],
    }),
    getStatisticsPrice: builder.query<
      GetStatisticsPriceChartApiResponse,
      GetStatisticsPriceChartApiArg
    >({
      query: (queryArg) => ({
        url: `/pricereport/${queryArg.reportId}/statistics_price`,
        method: 'POST',
        body: queryArg.body,
      }),
      providesTags: (result, error, queryArg) => [{type: 'price', id: queryArg.reportId}],
    }),
    getStatisticsPriceMap: builder.query<
      GetStatisticsPriceMapChartApiResponse,
      GetStatisticsPriceMapChartApiArg
    >({
      query: (queryArg) => ({
        url: `/pricereport/${queryArg.reportId}/statistics_mileage_price`,
        method: 'POST',
        body: queryArg.body,
      }),
      providesTags: (result, error, queryArg) => [{type: 'priceMap', id: queryArg.reportId}],
    }),
    getStatisticsV2: builder.query<GetStatisticsV2ApiResponse, GetStatisticsV2ApiArg>({
      query: (queryArg) => ({
        url: `/pricereport/${queryArg.reportId}/statistics_v2`,
        method: 'POST',
        body: queryArg.body,
      }),
      transformResponse: (
        response: GetStatisticsV2ApiResponse,
        _,
        queryArg
      ): GetStatisticsV2ApiResponse => {
        const offset = queryArg.body.offset ?? 0;
        return {
          ...response,
          cars: response.cars?.map((car, index) => ({
            ...car,
            position:
              index < (response.query_original_price_index ?? 0)
                ? index + 1 + offset
                : index + 2 + offset, // space for my vehicle
          })),
        };
      },
    }),
    getRequest: builder.query<GetRequestApiResponse, GetRequestApiArg>({
      query: (queryArg) => ({url: `/request/${queryArg.id}`}),
      providesTags: (result, error, queryArg) => [{type: 'request', id: queryArg.id}],
    }),
    getRequestCounts: builder.query<GetRequestCountsApiResponse, GetRequestCountsApiArg>({
      query: (queryArg) => ({url: `/request/${queryArg.id}/counts`}),
      providesTags: (result, error, queryArg) => [{type: 'requestCounts', id: queryArg.id}],
    }),
    createPriceReportFromRequest: builder.mutation<
      CreatePriceReportFromRequestApiResponse,
      CreatePriceReportFromRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/pricereport-from-request`,
        method: 'POST',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'request', id: queryArg.body.idRequest},
        {type: 'requestCounts', id: queryArg.body.idRequest},
      ],
    }),
    updateRequest: builder.mutation<UpdateRequestApiResponse, UpdateRequestApiArg>({
      query: (queryArg) => ({
        url: `/request/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, queryArg) => [
        {type: 'request', id: queryArg.id},
        {type: 'requestCounts', id: queryArg.id},
        {type: 'requestFeatures', id: queryArg.id},
      ],
    }),
    getRequestVinDetails: builder.query<VinDecoderData, GetRequestVinDetailsApiArg>({
      query: (queryArg) => ({url: `/request/${queryArg.id}/vin_details`}),
    }),
    getCarFeaturesList: builder.query<GetCarFeaturesListApiResponse, GetCarFeaturesListApiArg>({
      query: (queryArg) => ({
        url: `/request/${queryArg.id}/features`,
      }),
      providesTags: (result, error, queryArg) => [{type: 'requestFeatures', id: queryArg.id}],
    }),
    getSupportedMakes: builder.query<string[], void>({
      query: () => ({
        url: '/catalog/makes',
      }),
    }),
  }),
});

export const {
  useGetPriceReportQuery,
  useGetPriceReportCarDetailQuery,
  useGetPriceReportCarsDetailQuery,
  useGetStatisticsDaysOnStockQuery,
  useGetStatisticsFeaturesLevelQuery,
  useGetStatisticsMileageQuery,
  useGetStatisticsPriceMapQuery,
  useGetStatisticsPriceQuery,
  useGetAvailableCarsQuery,
  useGetStatisticsV2Query,
  useGetRequestQuery,
  useGetRequestCountsQuery,
  useCreatePriceReportFromRequestMutation,
  useUpdateRequestMutation,
  useLazyGetRequestVinDetailsQuery,
  useGetCarFeaturesListQuery,
  useGetSupportedMakesQuery,
} = priceReportApi;
