import {showNotification} from 'platform/components';

import {useParams} from 'react-router-dom';

import {i18n} from '@price-report/shared';

import {composePath} from 'shared';

import {useGetDownloadLinkMutation} from '../store/omneticToolsApi';
import {getOverviewPath} from '../utils/routing';
import {usePriceReport} from './usePriceReport';
import {useVehicleTranslatedInfo} from './useVehicleTranslatedInfo';

const PRICE_REPORT_DEV_URL = 'https://price-report.dev.carsdata.dev';

export const useDownloadPDF = () => {
  const params = useParams();
  const {priceReport} = usePriceReport();
  const {data: vehicleData} = useVehicleTranslatedInfo(priceReport?.vehicle);
  const [downloadLink, {isLoading: isPreparingDownload}] = useGetDownloadLinkMutation();

  const priceReportName = `PriceReport ${vehicleData.make} ${vehicleData.model}`;

  const handleDownload = () => {
    const isLocalhost = window.location.host.includes('localhost');
    const serverUrl = isLocalhost
      ? PRICE_REPORT_DEV_URL
      : window.location.protocol + '//' + window.location.host;
    const composePathOptions = {queryParams: {lang: i18n.language, pdf: 'true'}};
    const pathWithoutSearchParams = getOverviewPath(params).split('?')[0];
    const pathToDownload = `${serverUrl}${composePath(pathWithoutSearchParams, composePathOptions)}`;
    const filename = toASCII(priceReportName);

    downloadLink({content: pathToDownload, filename})
      .unwrap()
      .then(({url}) => {
        window.location.assign(url);
      })
      .catch(() => {
        showNotification.error(i18n.t('general.notifications.downloadError'));
      });
  };

  return {
    priceReportName,
    handleDownload,
    isPreparingDownload,
  };
};

const toASCII = (str: string) =>
  str
    .normalize('NFD') // Normalize the string to decomposed form (NFD): é => e + ´
    .replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
    .replace(/[^a-zA-Z0-9]/g, '_'); // Replace non-alphanumeric characters with underscores
