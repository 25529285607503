import {Box, getValueByDevice, HStack, Show, Spinner, useDevice, VStack} from 'platform/foundation';
import {currencies, formatCurrencySymbol, useFormatPercentage} from 'platform/locale';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  YAxisProps,
} from 'recharts';
import {CategoricalChartFunc} from 'recharts/types/chart/generateCategoricalChart';
import {useTheme} from 'styled-components';
import {match} from 'ts-pattern';

import {useEffect, useMemo, useState} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import {count, isNil, length} from 'ramda';
import {isNilOrEmpty, isNotNil, isNumber} from 'ramda-adjunct';

import {i18n, PriceReportType} from '@price-report/shared';

import {CurrencyCodeType, suffixTestId, TestIdProps, useQueryState} from 'shared';

import {useFilter} from '../../../../hooks/FilterContext';
import {CatalogueType} from '../../../../hooks/useCatalogue';
import {usePriceReportSearchParams} from '../../../../hooks/usePriceReportSearchParams';
import {useGetPriceReportCarsDetailQuery} from '../../../../store/priceReportApi';
import {isInCypress} from '../../../../utils/isInCypress';
import {QueryStateKeys} from '../../../../utils/routing';
import {ChartCustomValueElement} from './components/ChartCustomValueElement';
import {ChartLegend} from './components/ChartLegend';
import {ChartTooltip} from './components/ChartTooltip';
import {ChartTypeSwitch} from './components/ChartTypeSwitch';
import {SelectedVehicleCard} from './components/SelectedVehicleCard';
import {defaultChartType} from './consts/defaultChartType';
import {useSelectedVehicleIdQueryState} from './hooks/useSelectedVehicleIdQueryState';
import {useVisibleAvailabilitiesQueryState} from './hooks/useVisibleAvailabilitiesQueryState';
import {useXAxisTickFormatter} from './hooks/useXAxisTickFormatter';
import {
  Formatter,
  useYAxisTickFormatter,
  useYAxisTickFormatterWithUnitOnMobile,
} from './hooks/useYAxisTickFormatter';
import {renderReferenceLineX} from './renders/renderReferenceLineX';
import {renderReferenceLineY} from './renders/renderReferenceLineY';
import {ExtendedStatisticsCar, StatisticsChartItem} from './types';
import {ChartVariant} from './types/ChartVariant';
import {getAverageValueWithoutMyVehicle} from './utils/getAverageValueWithoutMyVehicle';
import {getXAxisLabel} from './utils/getXAxisLabel';
import {getXPosition} from './utils/getXPosition';
import {getYAxisLabel} from './utils/getYAxisLabel';
import {getYPosition} from './utils/getYPosition';
import {isOnStock} from './utils/isOnStock';
import {isSoldIn30Days} from './utils/isSoldIn30Days';
import {isSoldIn90Days} from './utils/isSoldIn90Days';
import {mapAvailableCarToExtendedStatisticCar} from './utils/mapAvailableCarToExtendedStatisticCar';

export interface StatisticsChartProps extends TestIdProps {
  priceReport: PriceReportType;
  data: ExtendedStatisticsCar[];
  catalogue: CatalogueType;
  isLoading?: boolean;
}

const CHART_MIN_HEIGHT = 110;
const AXIS_OFFSET = 100;
const ROUND_TO_VALUE = 100;
const axisDomain: YAxisProps['domain'] = [
  (minValue: number) =>
    Math.max(0, Math.floor((minValue - AXIS_OFFSET) / ROUND_TO_VALUE) * ROUND_TO_VALUE),
  'auto',
];

// TODO T20-27359: replace hex colors
export function StatisticsChart(props: StatisticsChartProps) {
  const theme = useTheme();
  const formatPercentage = useFormatPercentage();
  const device = useDevice();
  const [debug] = useQueryState(QueryStateKeys.Debug);
  const {filter} = useFilter();
  const {data: neighbours} = useGetPriceReportCarsDetailQuery(
    {reportId: props.priceReport.id, vehicleIds: props.priceReport.neighborAdIds ?? []},
    {skip: debug !== 'true' || isNilOrEmpty(props.priceReport.neighborAdIds)}
  );

  const [selectedVehicleId, setSelectedVehicleId] = useSelectedVehicleIdQueryState();
  const [activeTooltipIndex, setActiveTooltipIndex] = useState<number | null>(null);
  const [visibleAvailabilities, setVisibleAvailabilities] = useVisibleAvailabilitiesQueryState([
    'onStock',
    'soldIn30Days',
    'soldIn90Days',
  ]);
  const {chartVariant, setChartVariant} = usePriceReportSearchParams();
  const isScatterChart = chartVariant === 'priceMap';

  useEffect(() => {
    if (
      isNotNil(selectedVehicleId) &&
      !props.data.some((vehicle) => vehicle.ad_id === selectedVehicleId)
    ) {
      setSelectedVehicleId(null);
    }
  }, [filter, props.data, selectedVehicleId, setSelectedVehicleId]);

  const onMouseMoveBarChart: CategoricalChartFunc = (state) => {
    if (isNil(state)) {
      return;
    }
    if (!state.isTooltipActive) {
      setActiveTooltipIndex(null);
    }
    if (state.activePayload?.[0]?.payload?.isMyVehicle) {
      return;
    }
    if (isNumber(state.activeTooltipIndex)) {
      setActiveTooltipIndex(state.activeTooltipIndex);
    }
  };

  const onClick = (event: StatisticsChartItem) =>
    setSelectedVehicleId(selectedVehicleId === event.car.ad_id ? null : event.car.ad_id);

  const currencyCode = props.priceReport.sellingPrice.recommended.currency as CurrencyCodeType;
  const currencySymbol = formatCurrencySymbol(currencies, currencyCode);

  const xAxisTickFormatter = useXAxisTickFormatter(chartVariant);
  const yAxisTickFormatter = useYAxisTickFormatter(chartVariant);
  const yAxisTickFormatterWithUnitOnMobile = useYAxisTickFormatterWithUnitOnMobile(chartVariant);

  // mapped data must be memoized - when mapped data is changed, recharts reset current zoom to default
  const mappedData: StatisticsChartItem[] = useMemo(() => {
    const filteredCars = props.data.filter(
      (car) =>
        car.isMyVehicle ||
        ((visibleAvailabilities.includes('onStock') || !isOnStock(car)) &&
          (visibleAvailabilities.includes('soldIn30Days') || !isSoldIn30Days(car)) &&
          (visibleAvailabilities.includes('soldIn90Days') || !isSoldIn90Days(car)))
    );

    const filteredNeighbours =
      neighbours
        ?.map(mapAvailableCarToExtendedStatisticCar)
        .filter(() => chartVariant === 'priceMap') || [];

    return [...filteredCars, ...filteredNeighbours].map((car, index) => ({
      index,
      x: getXPosition(car, chartVariant),
      y: getYPosition(car, chartVariant),
      car,
    }));
  }, [props.data, neighbours, visibleAvailabilities, chartVariant]);

  const averageValueX = isScatterChart ? getAverageValueWithoutMyVehicle('x', mappedData) : null;
  const averageValueY = getAverageValueWithoutMyVehicle('y', mappedData);

  const myVehicleItem = mappedData.find((item) => item.car.isMyVehicle);

  const onStockCount = count(isOnStock, props.data);
  const soldIn30DaysCount = count(isSoldIn30Days, props.data);
  const soldIn90DaysCount = count(isSoldIn90Days, props.data);

  const Chart = isScatterChart ? ScatterChart : BarChart;
  const isAnimationActive = !isInCypress;

  const yReferenceLineLabelFormatter: Formatter = (value) =>
    match<ChartVariant, string>(chartVariant)
      .with('price', () =>
        i18n.t('entity.statisticsChart.labels.thousandsCurrency', {
          value: yAxisTickFormatter(value),
          currencySymbol,
        })
      )
      .with('priceMap', () =>
        i18n.t('entity.statisticsChart.labels.thousandsCurrency', {
          value: yAxisTickFormatter(value),
          currencySymbol,
        })
      )
      .with('mileage', () =>
        i18n.t('entity.statisticsChart.labels.thousandsKm', {
          value: yAxisTickFormatter(value),
        })
      )
      .with('featuresLevel', () => formatPercentage(value / 100, 'down'))
      .otherwise(() => yAxisTickFormatter(value));

  return (
    <VStack spacing={4} grow={1}>
      <Box paddingLeft={[0, 0, 19, 19]}>
        <HStack justify="space-between" align="center" spacing={4} wrap>
          <ChartLegend
            value={visibleAvailabilities}
            onChange={setVisibleAvailabilities}
            onStockCount={onStockCount}
            soldIn30DaysCount={soldIn30DaysCount}
            soldIn90DaysCount={soldIn90DaysCount}
          />
          <ChartTypeSwitch
            chartVariant={chartVariant}
            setChartVariant={setChartVariant}
            defaultChartType={defaultChartType}
            data-testid={suffixTestId('chartTypeSwitch', props)}
          />
        </HStack>
      </Box>
      <Box
        minHeight={CHART_MIN_HEIGHT}
        overflow="hidden"
        flexGrow={1}
        position="relative"
        data-testid={suffixTestId('chartWrapper', props)}
      >
        <AutoSizer>
          {({width, height}) => (
            <Chart
              data={mappedData}
              onMouseMove={!isScatterChart ? onMouseMoveBarChart : undefined}
              width={width}
              height={height}
            >
              <CartesianGrid stroke={theme.colors.palettes.neutral['30'][100]} />
              {isScatterChart ? (
                <Scatter
                  data={mappedData}
                  onClick={onClick}
                  shape={
                    <ChartCustomValueElement
                      selectedVehicleId={selectedVehicleId}
                      activeTooltipIndex={activeTooltipIndex}
                      chartType="scatter"
                    />
                  }
                  isAnimationActive={isAnimationActive}
                />
              ) : (
                <Bar
                  dataKey="y"
                  minPointSize={5}
                  radius={[4, 4, 0, 0]}
                  onClick={onClick}
                  shape={
                    <ChartCustomValueElement
                      selectedVehicleId={selectedVehicleId}
                      activeTooltipIndex={activeTooltipIndex}
                      chartType="bar"
                    />
                  }
                  isAnimationActive={isAnimationActive}
                />
              )}
              <YAxis
                dataKey="y"
                type={isScatterChart ? 'number' : undefined}
                tickFormatter={yAxisTickFormatterWithUnitOnMobile}
                tickLine={false}
                fontSize={12}
                axisLine={false}
                width={getValueByDevice(device, 48, 72, 72, 72)}
                padding={{top: 40}}
                domain={axisDomain}
              >
                <Label
                  value={getYAxisLabel(chartVariant, currencyCode, device)}
                  angle={-90}
                  position="insideLeft"
                  fontSize={14}
                />
              </YAxis>
              <XAxis
                dataKey="x"
                type={isScatterChart ? 'number' : undefined}
                axisLine={false}
                tickFormatter={xAxisTickFormatter}
                tickLine={false}
                fontSize={12}
                height={48}
                domain={isScatterChart ? axisDomain : undefined}
                padding={isScatterChart ? {right: 40} : undefined}
              >
                <Label value={getXAxisLabel(chartVariant)} position="insideBottom" fontSize={14} />
              </XAxis>
              {device !== 'mobile' && (
                <Tooltip
                  content={
                    <ChartTooltip
                      priceReport={props.priceReport}
                      data-testid={suffixTestId('chartTooltip', props)}
                    />
                  }
                  wrapperStyle={{outline: 'none'}}
                  cursor={false}
                />
              )}
              {myVehicleItem && (
                <>
                  {renderReferenceLineX({
                    value: myVehicleItem.x,
                    formatter: xAxisTickFormatter,
                    flagColor: 'black',
                    flagVerticalAlign: 'bottom',
                    flagHorizontalAlign: 'left',
                    lineColor: '#0A2442',
                    isLabelVisible: chartVariant === 'priceMap',
                  })}
                  {renderReferenceLineY({
                    value: myVehicleItem.y,
                    formatter: yReferenceLineLabelFormatter,
                    flagColor: 'black',
                    flagVerticalAlign: 'bottom',
                    lineColor: '#0A2442',
                  })}
                </>
              )}
              {length(mappedData) > 1 && (
                <>
                  {renderReferenceLineY({
                    value: averageValueY,
                    formatter: (value) => `Ø ${yReferenceLineLabelFormatter(value)}`,
                    lineColor: theme.colors.palettes.neutral[60][100],
                    flagColor: 'neutral',
                    flagVerticalAlign: 'top',
                    isFlagSubtle: true,
                    flagHorizontalAlign: 'right',
                    isDashed: true,
                  })}
                  {isNotNil(averageValueX) &&
                    renderReferenceLineX({
                      value: averageValueX,
                      formatter: (value) => `Ø ${xAxisTickFormatter(value)}`,
                      lineColor: theme.colors.palettes.neutral[60][100],
                      flagColor: 'neutral',
                      flagHorizontalAlign: 'right',
                      isFlagSubtle: true,
                      isDashed: true,
                      isLabelVisible: chartVariant === 'priceMap',
                    })}
                </>
              )}
            </Chart>
          )}
        </AutoSizer>
        <Show when={props.isLoading}>
          <Box position="absolute" top={0} left={25} right={0} bottom={20}>
            <VStack height="100%" align="center" justify="center">
              <Spinner />
            </VStack>
          </Box>
        </Show>
      </Box>
      <SelectedVehicleCard
        vehicleId={selectedVehicleId}
        priceReport={props.priceReport}
        catalogue={props.catalogue}
      />
    </VStack>
  );
}
