import {Dialog, Form, FormField, showNotification} from 'platform/components';
import {VStack} from 'platform/foundation';
import {object, string} from 'yup';

import {i18n, UserComplaintRequestBody} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {usePriceReport} from '../../../../../hooks/usePriceReport';
import {useUserComplaintMutation} from '../../../../../store/omneticApi';

type UserComplaintFormValues = {text: string; email: string};

interface UserComplaintDialogProps extends TestIdProps {
  isOpen: boolean;
  onClose: () => void;
}

const schema = object().shape({
  text: string().required(i18n.t('entity.errorReportDialog.validation.textRequired')),
  email: string()
    .nullable()
    .required(i18n.t('entity.errorReportDialog.validation.emailRequired'))
    .email(i18n.t('entity.errorReportDialog.validation.emailFormat')),
});

export function UserComplaintDialog(props: UserComplaintDialogProps) {
  const {priceReport} = usePriceReport();
  const [userComplaint, {isLoading}] = useUserComplaintMutation();

  const onSubmit = async (values: UserComplaintFormValues) => {
    if (!priceReport?.id) {
      throw new Error('Report id is not defined');
    }
    const arg: UserComplaintRequestBody = {
      priceReportId: priceReport.id,
      url: window.location.href,
      ...values,
    };
    await userComplaint(arg)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('entity.errorReportDialog.labels.successMessage'));
        props.onClose();
      })
      .catch(() => {
        // TODO T20-32317 Implement handleApiError
        showNotification.error(i18n.t('entity.errorReportDialog.labels.failureMessage'));
      });
  };

  return (
    <Form onSubmit={onSubmit} schema={schema}>
      {(control, formApi) => (
        <Dialog
          title={i18n.t('entity.errorReportDialog.labels.title')}
          size="small"
          isOpen={props.isOpen}
          onClose={props.onClose}
          onCloseComplete={props.onClose}
          buttons={[
            {
              title: i18n.t('general.actions.cancel'),
              variant: 'secondary',
              onClick: props.onClose,
            },
            {
              title: i18n.t('general.actions.send'),
              onClick: formApi.handleSubmit(onSubmit),
              isLoading,
            },
          ]}
          data-testid={suffixTestId('complaintDialog', props)}
        >
          <VStack spacing={4}>
            <FormField
              name="text"
              type="textarea"
              control={control}
              label={i18n.t('entity.errorReportDialog.labels.description')}
              rows={5}
              isRequired
              isResizable
              data-testid={suffixTestId('text', props)}
            />
            <FormField
              name="email"
              type="email"
              control={control}
              label={i18n.t('entity.errorReportDialog.labels.email')}
              isRequired
              data-testid={suffixTestId('email', props)}
            />
          </VStack>
        </Dialog>
      )}
    </Form>
  );
}
