import {isObject} from 'ramda-adjunct';

import {AddrDetailApiResponse} from '@price-report/shared';

export function parseAddress(address: AddrDetailApiResponse) {
  if (isObject(address)) {
    const streetNumber = `${address.descriptiveNumber ?? ''}${address.descriptiveNumber && address.orientationNumber ? '/' : ''}${address.orientationNumber ?? ''}`;
    const streetWithNumber = `${address.street ?? ''}${streetNumber ? ' ' : ''}${streetNumber}`;
    return `${streetWithNumber}${streetWithNumber && address.city ? ', ' : ''}${address.city ?? ''}`;
  }

  return null;
}
