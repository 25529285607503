import {Flag, Separator, Tooltip} from 'platform/components';
import {
  Box,
  Clickable,
  Heading,
  Hide,
  HStack,
  Icon,
  Show,
  Space,
  Text,
  ThemeIconKey,
  VStack,
} from 'platform/foundation';
import {useFormatCurrency, useFormatPercentage} from 'platform/locale';

import {isNil} from 'ramda';
import {isNotNil} from 'ramda-adjunct';

import {
  AvailableCar,
  i18n,
  mileageFormatter,
  powerFormatter,
  PriceReportType,
} from '@price-report/shared';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {CompareTrend} from '../../../components/CompareTrend/CompareTrend';
import {CatalogueType} from '../../../hooks/useCatalogue';
import {useVehicleTranslatedInfo} from '../../../hooks/useVehicleTranslatedInfo';
import {getDifference} from '../../../utils/getDifference';
import {getPrice} from '../../../utils/getPrice';
import {translateSellerType} from '../../../utils/translateSellerType';
import {openNewTab} from '../utils/openNewTab';
import {FeatureList} from './FeatureList';
import {HorizontalValue} from './HorizontalValue';
import {VerticalValue} from './VerticalValue';

const DEFAULT_VALUE = 'N/A';

interface RowHeaderProps extends TestIdProps {
  priceReport: PriceReportType;
  catalogue: CatalogueType;
  vehicle: AvailableCar;
  isMyVehicle: boolean;
}

export function RowHeader(props: RowHeaderProps) {
  const formatPercentage = useFormatPercentage();
  const formatCurrency = useFormatCurrency();

  const {getTransmission, getFuelType, getDrive, getCarStyle} = props.catalogue;
  const {data: vehicleTranslatedInfo} = useVehicleTranslatedInfo(
    isNotNil(props.vehicle.model)
      ? {...props.priceReport?.vehicle, modelFamily: props.vehicle.model}
      : props.priceReport?.vehicle
  );

  const vehicleDetailValues = buildArray<{icon: ThemeIconKey; text: string | number}>([
    {icon: 'automotive/engine', text: powerFormatter(props.vehicle.power)},
    {icon: 'automotive/differential', text: getDrive(props.vehicle.drive_type)},
    {icon: 'automotive/transmission', text: getTransmission(props.vehicle.transmission)},
    {icon: 'automotive/fuel_type', text: getFuelType(props.vehicle.fuel_type)},
    {icon: 'automotive/car', text: getCarStyle(props.vehicle.car_style)},
  ]).whenNot(props.isMyVehicle, {
    icon: 'automotive/location',
    text:
      translateSellerType(props.vehicle.seller?.type ?? 'SELLERTYPE_PRIVATE_SELLER') ??
      DEFAULT_VALUE,
  });

  const vehiclePrice = getPrice(props.vehicle, props.priceReport);
  const priceDifference = getDifference(
    vehiclePrice,
    props.priceReport.sellingPrice.recommended.value
  );
  const currency = props.priceReport.sellingPrice.recommended.currency;
  const yearDifference = getDifference(
    props.vehicle.year,
    props.priceReport?.vehicle.firstRegistrationOnYear
  );
  const mileageDifference = getDifference(
    props.vehicle.mileage,
    props.priceReport?.vehicle.mileage
  );
  const featuresDifference = getDifference(
    props.vehicle.feature_score,
    props.priceReport?.featuresLevel,
    100
  );

  const handleTitleClick = () => {
    if (!props.vehicle.is_sold) {
      openNewTab(props.vehicle.url?.full);
    }
  };

  return (
    <HStack wrap spacing={3} justify="space-between">
      <VStack spacing={3} minWidth={0}>
        <Box>
          <Clickable
            onClick={handleTitleClick}
            isDisabled={!!props.vehicle.is_sold || props.isMyVehicle}
          >
            <HStack align="center">
              <Heading size={4} data-testid={suffixTestId('label', props)}>
                {`${vehicleTranslatedInfo.make} ${vehicleTranslatedInfo.model}`}
              </Heading>
              <Hide when={props.vehicle.is_sold || props.isMyVehicle}>
                <Space horizontal={2} />
                <Icon value="action/open_in_new" size={4} />
              </Hide>
            </HStack>
          </Clickable>
          <Space vertical={1} />
          <HStack spacing={4} wrap>
            {vehicleDetailValues.map((detail) => (
              <HStack key={detail.text}>
                <Icon value={detail.icon} size={4} />
                <Space horizontal={2} />
                <Text
                  inline
                  size="xSmall"
                  color="secondary"
                  alternative
                  noWrap
                  data-testid={suffixTestId('details', props)}
                >
                  {detail.text}
                </Text>
              </HStack>
            ))}
          </HStack>
        </Box>
        <FeatureList
          firstWhite
          values={props.vehicle.highlight_features}
          isBold={false}
          isRow
          spacing={2}
          textColor="primary"
          sortable
          expandable
          isMyVehicle={props.isMyVehicle}
          data-testid={suffixTestId('columnHeader', props)}
        />
      </VStack>
      <Separator spacing={0} />
      <Show onMobile>
        <Box flex={1}>
          <VStack spacing={3}>
            <HStack justify="space-between" align="center">
              <Text size="xSmall" color="secondary">
                {i18n.t('entity.table.firstRegistration')}
              </Text>
              <HorizontalValue
                value={props.vehicle.year}
                difference={props.isMyVehicle ? undefined : yearDifference}
                isMyVehicle={props.isMyVehicle}
                data-testid={suffixTestId('year', props)}
              />
            </HStack>
            <HStack justify="space-between" align="center">
              <Text size="xSmall" color="secondary">
                {i18n.t('entity.table.featureLevel')}
              </Text>
              <HorizontalValue
                value={props.vehicle.feature_score}
                difference={props.isMyVehicle ? undefined : featuresDifference}
                formatter={(value) =>
                  isNotNil(value) ? formatPercentage(value, 'down') : DEFAULT_VALUE
                }
                differenceFormatter={(value) =>
                  // Feature difference is already rounded – formatting has to be the nearest due to rounding problems (0.29 * 100 = 28.999999999999996)
                  isNotNil(value) ? formatPercentage(value, 'nearest') : DEFAULT_VALUE
                }
                withRank
                isMyVehicle={props.isMyVehicle}
                data-testid={suffixTestId('featureScore', props)}
              />
            </HStack>
            <HStack justify="space-between" align="center">
              <Text size="xSmall" color="secondary">
                {i18n.t('entity.table.mileage')}
              </Text>
              <HorizontalValue
                value={props.vehicle.mileage}
                difference={props.isMyVehicle ? undefined : mileageDifference}
                formatter={mileageFormatter}
                differenceReverseColor
                isMyVehicle={props.isMyVehicle}
                data-testid={suffixTestId('mileage', props)}
              />
            </HStack>
            <HStack justify="space-between" align="center">
              <Text size="xSmall" color="secondary">
                {i18n.t('page.overview.inAdvertising')}
              </Text>
              <Heading size={5} alternative>
                {i18n.t('general.labels.nDays', {
                  count: props.vehicle.days_on_stock ?? 0,
                })}
              </Heading>
            </HStack>
            <HStack justify="space-between">
              <Text size="xSmall" color="secondary">
                {i18n.t('entity.charts.labels.price')}
              </Text>
              <VStack align="flex-end" spacing={1}>
                <HorizontalValue
                  value={vehiclePrice}
                  difference={priceDifference}
                  formatter={(value) => (isNotNil(value) && formatCurrency(value, currency)) || ''}
                  differenceReverseColor
                  isPrice
                  hasVatFlag={props.vehicle.vat_reclaimable}
                  isMyVehicle={props.isMyVehicle}
                  data-testid={suffixTestId('price', props)}
                />
                <Hide when={isNil(props.vehicle.first_scrape_price_with_vat)}>
                  <Text size="xxSmall" color="secondary" strikethrough>
                    {formatCurrency(props.vehicle.first_scrape_price_with_vat!, currency)}
                  </Text>
                </Hide>
              </VStack>
            </HStack>
          </VStack>
        </Box>
      </Show>
      <Hide onMobile>
        <HStack grow={1} wrap>
          <Box flex={1}>
            <VStack spacing={1}>
              <Heading size={5} alternative>
                {i18n.t('general.labels.nDays', {
                  count: props.vehicle.days_on_stock ?? 0,
                })}
              </Heading>
              <Hide when={props.isMyVehicle}>
                <Text size="xSmall" color="secondary">
                  {i18n.t('general.labels.inAdvertisement')}
                </Text>
              </Hide>
            </VStack>
          </Box>
          <Box flex={1}>
            <VerticalValue
              value={props.vehicle.year}
              difference={props.isMyVehicle ? undefined : yearDifference}
              isRow
              isMyVehicle={props.isMyVehicle}
              data-testid={suffixTestId('year', props)}
            />
          </Box>
          <Box flex={1}>
            <VerticalValue
              value={props.vehicle.feature_score}
              difference={props.isMyVehicle ? undefined : featuresDifference}
              formatter={(value) =>
                isNotNil(value) ? formatPercentage(value, 'down') : DEFAULT_VALUE
              }
              differenceFormatter={(value) =>
                // Feature difference is already rounded – formatting has to be the nearest due to rounding problems (0.29 * 100 = 28.999999999999996)
                isNotNil(value) ? formatPercentage(value, 'nearest') : DEFAULT_VALUE
              }
              withRank
              isRow
              isMyVehicle={props.isMyVehicle}
              data-testid={suffixTestId('featureScore', props)}
            />
          </Box>
          <Box flex={1}>
            <VerticalValue
              value={props.vehicle.mileage}
              difference={props.isMyVehicle ? undefined : mileageDifference}
              formatter={mileageFormatter}
              differenceReverseColor
              isRow
              isMyVehicle={props.isMyVehicle}
              data-testid={suffixTestId('mileage', props)}
            />
          </Box>
          <Box width={30}>
            <VStack spacing={1} align="flex-end">
              <HStack spacing={1} align="center">
                <Show when={props.vehicle.vat_reclaimable}>
                  <Tooltip label={i18n.t('general.labels.vatDeductible')}>
                    <Flag
                      colorScheme="black"
                      size="small"
                      label={i18n.t('general.labels.vat')}
                      data-testid={suffixTestId('vatDeductible', props)}
                    />
                  </Tooltip>
                </Show>
                <Heading size={5} data-testid={suffixTestId('price', props)}>
                  {formatCurrency(vehiclePrice, currency)}
                </Heading>
              </HStack>
              <Hide when={props.isMyVehicle}>
                <CompareTrend
                  value={priceDifference}
                  formatter={(value) => formatCurrency(value, currency) ?? ''}
                  reverseColor
                />
              </Hide>
              <Hide when={isNil(props.vehicle.first_scrape_price_with_vat)}>
                <Text size="xxSmall" color="secondary" strikethrough>
                  {formatCurrency(props.vehicle.first_scrape_price_with_vat!, currency)}
                </Text>
              </Hide>
            </VStack>
          </Box>
        </HStack>
      </Hide>
    </HStack>
  );
}
