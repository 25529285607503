import {Label, Search, showNotification, useAnimatedPopper} from 'platform/components';
import {Box, Clickable, getSize, Integer, Text, VStack} from 'platform/foundation';
import {useLocale} from 'platform/locale';
import styled from 'styled-components';

import {KeyboardEvent, useCallback, useEffect, useRef, useState} from 'react';
import {useController, UseControllerProps} from 'react-hook-form';

import {isNil, isNotNil} from 'ramda';
import {floor, isNotNilOrEmpty, isObject} from 'ramda-adjunct';

import {
  AddrSuggestionsApiResponse,
  FilterFormAddress,
  FilterFormData,
  i18n,
} from '@price-report/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {usePriceReport} from '../../../hooks/usePriceReport';
import {useAddrSuggestionsQuery, useLazyAddrDetailQuery} from '../../../store/addressApi';
import {useHandleKeyDownEvent} from '../hooks/useHandleKeydownEvent';
import {parseAddress} from '../utils/parseAddress';

export function AddressSearch(props: UseControllerProps<FilterFormData> & RequiredTestIdProps) {
  const {language} = useLocale();
  const {priceReport} = usePriceReport();
  const {
    field: {value, onChange},
  } = useController(props);
  const widthRef = useRef<HTMLDivElement>(null);

  const [getAddressDetail] = useLazyAddrDetailQuery();

  const defaultLabel =
    (isObject(value) && 'label' in (value as object) && (value as FilterFormAddress)?.label) ||
    null;
  const [searchText, setSearchText] = useState<string | null>(defaultLabel);
  const [isOptSelected, setIsOptSelected] = useState<boolean>(false);

  const {data: suggestions} = useAddrSuggestionsQuery(
    {
      term: searchText,
      country: priceReport!.market,
      language,
    },
    {skip: isNil(searchText) || isNil(priceReport)}
  );
  const {Popper, closePopper, openPopper, referenceRef, popperProps, isOpen} = useAnimatedPopper({
    placement: 'bottom-start',
  });

  const suggestionOptions = suggestions && suggestionsToOptions(suggestions);

  const handleSubmitAddress = useCallback(
    (placeId: string, label?: string) => {
      getAddressDetail({placeId, language})
        .unwrap()
        .then((addressResponse) => {
          if (isObject(addressResponse)) {
            const address: FilterFormAddress = {
              label: label ?? parseAddress(addressResponse) ?? '',
              lng: isNotNil(addressResponse.gps) ? parseFloat(addressResponse.gps.lng) : 0,
              lat: isNotNil(addressResponse.gps) ? parseFloat(addressResponse.gps.lat) : 0,
            };
            onChange(address);
          }
          setSearchText(label ?? parseAddress(addressResponse));
        })
        .then(() => setIsOptSelected(true))
        .then(closePopper)
        .catch(() => {
          // TODO T20-32317 Implement handleApiError
          showNotification.error(i18n.t('general.labels.error'));
        });
    },
    [getAddressDetail, language, closePopper, onChange]
  );

  const handleClearAddress = useCallback(() => {
    onChange(null);
  }, [onChange]);

  const [focusedOptionIndex] = useHandleKeyDownEvent({
    handleSubmitAddress,
    isOpen,
    suggestions: suggestionOptions,
  });

  useEffect(() => {
    if (isNil(value)) {
      setSearchText(null);
    }
  }, [value]);

  useEffect(() => {
    if (isNotNilOrEmpty(searchText) && !isOptSelected && searchText !== defaultLabel) {
      openPopper();
    }
  }, [searchText, openPopper, isOptSelected, defaultLabel]);

  return (
    <>
      <Box ref={widthRef}>
        <VStack spacing={1}>
          <Label>{i18n.t('entity.filterForm.labels.address')}</Label>
          <div
            ref={referenceRef}
            onClick={openPopper}
            onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          >
            <Search
              value={searchText}
              onFocus={() => {
                openPopper();
                setIsOptSelected(false);
              }}
              onClear={() => {
                closePopper();
                handleClearAddress();
              }}
              onChange={setSearchText}
              data-testid={suffixTestId('address-search', props)}
            />
          </div>
        </VStack>
      </Box>

      <Popper
        {...popperProps}
        isOpen={isOpen && isNotNilOrEmpty(suggestionOptions) && isNotNilOrEmpty(searchText)}
      >
        <Box
          paddingTop={2}
          width={(floor((widthRef.current?.clientWidth ?? 0) / 4) as Integer) ?? '100%'}
        >
          <Box
            backgroundColor="general.white"
            borderRadius="small"
            boxShadow="elevation_2"
            padding={2}
          >
            <VStack>
              {suggestionOptions?.map((item, index) => (
                <Clickable
                  key={item.value}
                  onClick={() => {
                    setIsOptSelected(true);
                    handleSubmitAddress(item.value, item.label);
                  }}
                  data-testid={suffixTestId(`address-option-${index + 1}`, props)}
                >
                  <BoxWithHover isFocused={focusedOptionIndex === index}>
                    <Text size="small">{item.label}</Text>
                  </BoxWithHover>
                </Clickable>
              ))}
            </VStack>
          </Box>
        </Box>
      </Popper>
    </>
  );
}

const BoxWithHover = styled.div<{isFocused: boolean}>`
  padding: ${getSize(2)};
  border-radius: ${({theme}) => theme.radii.xSmall};
  ${({isFocused, theme}) =>
    isFocused ? ` background-color: ${theme.colors.palettes.blue[10][100]};` : ``}

  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.blue[20][100]};
  }
`;

const suggestionsToOptions = (val: AddrSuggestionsApiResponse) =>
  val.map((item) => ({
    value: item?.placeId ?? '',
    label: item?.formattedAddress ?? i18n.t('entity.filterForm.labels.noAddresses'),
  }));
